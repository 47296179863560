import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OKTA_CONFIG, OktaAuthModule, } from '@okta/okta-angular';

export class EnvironmentService {
  static config: any;
}
@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    OktaAuthModule
  ],
  exports: [
    OktaAuthModule
  ]
})

export class OktaModule {
  public static forRoot(oktaAuth: any, config: any): ModuleWithProviders<any> {
    EnvironmentService.config = config;
    return { ngModule: OktaModule, providers: [{ provide: OKTA_CONFIG, useValue: { oktaAuth } }] };
  }
}
